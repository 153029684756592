import React, { useState } from 'react';

import './App.css';
import Banner from './components/banner'
import LanguageButton from './components/language-button';
import Explanation from './components/explanation';
import Form from './components/form';
import ScrollTopButton from './components/scroll-top-button';
import Footer from './components/footer';

function App() {

  const [isJapanese, setIsJapanese] = useState(true);

  return (
    <div>
      <div className="page-width flex-box">
        <Banner />

        <LanguageButton
          setLanguage={isJapanese}
          onChange={() => setIsJapanese(!isJapanese)}
        />

        <Explanation
          setLanguage={isJapanese}
        />

        <Form
          setLanguage={isJapanese}
        />

        <ScrollTopButton />
      </div>
      <Footer />
    </div>
  );
}

export default App;
