import React from 'react';
import banner from '../assets/images/oshikatsu-banner.jpg';

export default function Banner() {

  return (
    <a className="home-banner-button" href='/'>
      <img
        id="banner"
        className="banner"
        priority="true"
        alt="not found"
        src={banner}
      />
    </a>
  );
}
