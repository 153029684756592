/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import templateRed from '../assets/images/oshikatsu-template-red.jpg';
import templateBlue from '../assets/images/oshikatsu-template-blue.jpg';
import templateGreen from '../assets/images/oshikatsu-template-green.jpg';
import templateYellow from '../assets/images/oshikatsu-template-yellow.jpg';
import cornerTagRed from '../assets/images/watashi-no-oshikatsu-corner-tag-red.png';
import cornerTagBlue from '../assets/images/watashi-no-oshikatsu-corner-tag-blue.png';
import cornerTagGreen from '../assets/images/watashi-no-oshikatsu-corner-tag-green.png';
import cornerTagYellow from '../assets/images/watashi-no-oshikatsu-corner-tag-yellow.png';

export default function Output(props) {

  const outputTextLanguages = {
    japanese: {
      heading: '写真か「保存する」ボタンをタップすると、ダウンロードが始まります。',
      age: '歳',
      downloadButton: '保存する',
      newOshikatsuButton: '新規作成',
    },
    english: {
      heading: 'Click on the picture or the "Download" button to download your poster',
      age: ' years old',
      downloadButton: 'Download',
      newOshikatsuButton: 'New "Oshi-Katsu"',
    },
  };

  let outputText = props.setLanguage ? outputTextLanguages.japanese : outputTextLanguages.english;

  const templateDesigns = {
    'red': [templateRed, cornerTagRed],
    'blue': [templateBlue, cornerTagBlue],
    'green': [templateGreen, cornerTagGreen],
    'yellow': [templateYellow, cornerTagYellow],
  };

  function formScroll() {
    window.location.replace("/#form");
  };

  const clickHandler = (e) => {
    e.preventDefault();
    props.setFormData({
      design: '',
      oshikatsu: '',
      mirai: '',
      penname: '',
      age: '',
      image: '',
    });
    props.setSelectedImage(null);
    formScroll();
  };

  const screenshotRef = useRef(this);

  function screenshotDownload() {
    htmlToImage.toJpeg(screenshotRef.current, { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'watashi-no-oshikatsu.jpeg';
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <div id="result" className="result">
      {props.submittedImage && (
        <div>
          <div id="poster" className="poster flex-box">
            <h2 id="download-heading" className="heading download-heading">
              {outputText.heading}
            </h2>
            <div className="output-buttons flex-box">
              <button
                id="download-button"
                className="download-button buttons"
                onClick={screenshotDownload}
              >
                {outputText.downloadButton}
              </button>
              <button
                id="new-oshikatsu-button"
                className="new-oshikatsu-button buttons"
                onClick={clickHandler}
              >
                {outputText.newOshikatsuButton}
              </button>
            </div>
            <div id="screenshot-div" ref={screenshotRef} className="screenshot-div">
              <img
                className="template-base"
                id="template-base"
                alt="not found"
                src={templateDesigns[props.submittedText.design][0]}
              />

              <img
                className="picture"
                id="picture"
                alt="not found"
                src={URL.createObjectURL(props.submittedImage)}
              />

              <img
                className="corner-tag"
                id="corner-tag"
                alt="not found"
                src={templateDesigns[props.submittedText.design][1]}
              />

              <p className="poster-oshikatsu" id="poster-oshikatsu">{props.submittedText.oshikatsu}</p>
              <p className="poster-mirai" id="poster-mirai">{props.submittedText.mirai}</p>
              <p className="poster-name-age" id="poster-name-age">
                {props.submittedText.penname}{props.submittedText.penname ? (props.submittedText.age && `・${props.submittedText.age}${outputText.age}`) : (props.submittedText.age && `${props.submittedText.age}${outputText.age}`)}
              </p>
              <a className="download-div" onClick={screenshotDownload}></a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
