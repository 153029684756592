import React from 'react';
import scrollTopImage from '../assets/images/scroll-top-image.png';

export default function ScrollTopButton() {

  function scrollToTop() {
    window.location.replace("/#banner")
  };

  return (
    <button
      className="scroll-top-button"
      onClick={scrollToTop}
    >
      <img
        id="scroll-top-image"
        className="scroll-top-image"
        alt="not found"
        src={scrollTopImage}
      />
    </button>
  );
}
