import React, { useState } from 'react';
import templateRed from '../assets/images/oshikatsu-template-red.jpg';
import templateBlue from '../assets/images/oshikatsu-template-blue.jpg';
import templateGreen from '../assets/images/oshikatsu-template-green.jpg';
import templateYellow from '../assets/images/oshikatsu-template-yellow.jpg';
import RadioButton from './radio-button';
import ShareButtons from './share-buttons';
import Output from './output';

export default function Form(props) {

  const formInfo = {
    Japanese: {
      designQuestion: 'ポスターのデザインを選択してください',
      oshikatsuQuestion: 'あなたの「推し勝★」を教えてください！',
      oshikatsuNote: '※8字まで入力できます。',
      oshikatsuMaxLength: 8,
      oshikatsuPlaceholder: 'ホワイトサウルス',
      miraiQuestion: '「推し勝★」でどんな未来を創る？',
      miraiNote: '※36字まで入力できます。',
      miraiMaxLength: 36,
      miraiPlaceholder: '迫力満点！勝山のシンボル！\n迫力満点！恐竜博物館のGatekeeper！',
      pennameQuestion: 'あなたのペンネームは何ですか？（任意）',
      pennameNote: '※15字まで入力できます。',
      pennamePlaceholder: '勝山たろう',
      ageQuestion: 'おいくつですか？（任意）',
      imageQuestion: '写真を選択してください！',
      imageNote: '※横向きの写真推奨。縦の写真を選択する場合、全部が表示されない可能性があります。',
      submitButtonText: '「推し勝★」ポスターを創る！',
    },
    English: {
      designQuestion: 'Choose your poster design',
      oshikatsuQuestion: 'What is your "Oshi-Katsu"?',
      oshikatsuNote: '※ Please enter up to 15 characters',
      oshikatsuMaxLength: 15,
      oshikatsuPlaceholder: 'Whitesaurus',
      miraiQuestion: 'What is your belief for the future?',
      miraiNote: '※ Please enter up to 54 characters',
      miraiMaxLength: 54,
      miraiPlaceholder: "The symbol of Katsuyama and gatekeeper of the museum!",
      pennameQuestion: 'What is your nickname? (Optional)',
      pennameNote: '※ Please enter up to 15 characters',
      pennamePlaceholder: 'Taro Katsuyama',
      ageQuestion: 'How old are you? (Optional)',
      imageQuestion: 'Choose a picture',
      imageNote: '※ The template only has space for landscape images so portrait images will be cropped to fit',
      submitButtonText: 'Generate My "Oshi-Katsu" Poster!',
    }
  };

  let formShow = props.setLanguage ? formInfo.Japanese : formInfo.English;

  const [formData, setFormData] = useState({
    design: 'red',
    oshikatsu: '',
    mirai: '',
    penname: '',
    age: '',
    image: '',
  });

  const [selectedImage, setSelectedImage] = useState(null);

  const [submittedImage, setSubmittedImage] = useState(null);

  const [submittedText, setSubmittedText] = useState({
    design: '',
    oshikatsu: '',
    mirai: '',
    penname: '',
    age: '',
  });

  const imageHandler = (e) => {
    if (e.target.files[0].size > 4000000) {
      props.setLanguage ? alert("4MBまでのファイルが選択できます") : alert("File over size limit. Please select an image up to 4MB");
      setFormData({
        ...formData,
        image: ''
      });
      setSelectedImage(null);
    } else {
      setSelectedImage(e.target.files[0]);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    };
    e.target.setCustomValidity("");
  };

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    e.target.setCustomValidity("");
  };

  const existenceValidity = (e) => {
    props.setLanguage ? (
      e.target.name === "image" ? e.target.setCustomValidity("写真を選択してください") : e.target.setCustomValidity("入力してください")
    ) : (
      e.target.name === "image" ? e.target.setCustomValidity("Please select an image") : e.target.setCustomValidity("Required field")
    );
  };

  const ageLimitValidity = (e) => {
    props.setLanguage ? (
      e.target.setCustomValidity("0から120まで入力できます")
    ) : (
      e.target.setCustomValidity("Please enter an age between 0 and 120")
    );
  };

  function posterScroll() {
    window.location.replace("/#poster");
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmittedImage(e.target.image.files[0]);
    setSubmittedText(formData);
    setTimeout(posterScroll, 500);
  };

  const templateDesigns = {
    'red': templateRed,
    'blue': templateBlue,
    'green': templateGreen,
    'yellow': templateYellow,
  };

  return (
    <div>
      <form id="form" onSubmit={handleSubmit}>
        <div className="form-div flex-box">
          <div className="form flex-box">
            <div>
              <label className="form-heading">
                {formShow.designQuestion}
              </label>
              <div className="design-options-div flex-box">
                <div className="design-options flex-box">
                  {Object.keys(templateDesigns).map((colour) => (
                    <RadioButton
                      key={colour}
                      onChange={changeHandler}
                      value={colour}
                      src={templateDesigns[colour]}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="image-question-div flex-box">
              <label htmlFor="image" className="form-heading">
                {formShow.imageQuestion}
              </label>
              <p className="form-note image-note">{formShow.imageNote}</p>
              <div className="image-input-div">
                {selectedImage && (
                  <img
                    className="selected-image-small"
                    alt="not found"
                    src={URL.createObjectURL(selectedImage)}
                  />
                )}

                <input
                  className="image-input"
                  type="file"
                  id="image"
                  name="image"
                  value={formData.image}
                  onChange={imageHandler}
                  required={true}
                  onInvalid={existenceValidity}
                />
              </div>
            </div>

            <div>
              <label htmlFor="oshikatsu" className="form-heading">
                {formShow.oshikatsuQuestion}
              </label>
              <p className="form-note">{formShow.oshikatsuNote}</p>
              <div className="input-div flex-box">
                <input
                  type="text"
                  name="oshikatsu"
                  id="oshikatsu"
                  value={formData.oshikatsu}
                  maxLength={formShow.oshikatsuMaxLength}
                  className="form-input oshikatsu"
                  placeholder={formShow.oshikatsuPlaceholder}
                  required={true}
                  onInvalid={existenceValidity}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div>
              <label htmlFor="mirai" className="form-heading">
                {formShow.miraiQuestion}
              </label>
              <p className="form-note">{formShow.miraiNote}</p>
              <div className="input-div flex-box">
                <textarea
                  name="mirai"
                  id="mirai"
                  value={formData.mirai}
                  maxLength={formShow.miraiMaxLength}
                  className="form-input mirai"
                  placeholder={formShow.miraiPlaceholder}
                  required={true}
                  onInvalid={existenceValidity}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div>
              <label htmlFor="penname" className="form-heading">
                {formShow.pennameQuestion}
              </label>
              <p className="form-note">{formShow.pennameNote}</p>
              <div className="input-div flex-box">
                <input
                  type="text"
                  name="penname"
                  id="penname"
                  value={formData.penname}
                  maxLength={15}
                  className="form-input penname"
                  placeholder={formShow.pennamePlaceholder}
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div>
              <label htmlFor="age" className="form-heading">
                {formShow.ageQuestion}
              </label>
              <p className="form-note"></p>
              <div className="input-div flex-box">
                <input
                  type="number"
                  name="age"
                  id="age"
                  value={formData.age}
                  min={0}
                  max={120}
                  placeholder="70"
                  className="form-input age"
                  onInvalid={ageLimitValidity}
                  onChange={changeHandler}
                />
              </div>
            </div>


          </div>
        </div>

        <div className="submit-button-div flex-box">
          <button
            type="submit"
            className="submit-button buttons"
          >
            {formShow.submitButtonText}
          </button>
        </div>
      </form>

      <ShareButtons
        setLanguage={props.setLanguage}
      />

      <Output
        setLanguage={props.setLanguage}
        setSelectedImage={setSelectedImage}
        setFormData={setFormData}
        submittedImage={submittedImage}
        submittedText={submittedText}
      />
    </div>

  );
}
