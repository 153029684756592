import React from 'react';

export default function LanguageButton(props) {

  return (
    <div className="language-button-div">
      <span>
        <button
          type="button"
          className="language-button buttons"
          onClick={props.onChange}
        >
          {!props.setLanguage ? "🇯🇵 日本語" : "🇬🇧/🇺🇸 English"}
        </button>
      </span>
    </div>
  );
}
