import React from 'react';
import posterExampleJp from '../assets/images/poster-example-jp.jpg';
import posterExampleEn from '../assets/images/poster-example-en.jpg';

export default function Explanation(props) {
  const explanationText = {
    Japanese: {
      title: `「推し勝★」メーカー`,
      body1: `自分の「推し勝★」ポスターを創ってみましょう！\n\n\n`,
      body2: `「推し勝★」とは？\n\n`,
      body3: `コンセプトの説明や詳細などは`,
      href: `https://www.city.katsuyama.fukui.jp/soshiki/2/28532.html`,
      linkText: `こちら`,
      body4: `です。`,
      body5: `\n\n\n「推し勝★」メーカーの使い方`,
      body6: `\n\n「推し勝★」メーカーに：\n\n- 私の「推し勝★」\n- 創る未来\n- ペンネーム\n- 年齢\n- 写真\n\nを入力すると、自分の「推し勝★」ポスターが創れます！`,
      imageNote: `ポスターの例`,
      body7: `完成したポスターをタップするか「保存する」ボタンをタップすると保存できます！*\nポスターを保存したら、「#私の推し勝」「#勝山しか勝たん山」のハッシュタグでSNSに投稿しましょう！\nでは、「推し勝★」メーカーで楽しみましょう！\n\n`,
      note: `*iPhoneでは保存できませんので、iPhoneの方はスクリーンショットを撮ってください。\n"音量を減らす"ボタンと"画面をロックする"ボタンを同時に押すと、スクリーンショットが撮れます。ご迷惑をおかけして申し訳ありません。`,
    },
    English: {
      title: `My "Oshi-Katsu" Generator!`,
      body1: `Create your own "Oshi-Katsu" poster!\n\n\n`,
      body2: `What is "Oshi-Katsu"?\n\n`,
      body3: `"Oshi" is used when referring to your favourite idol or celebrity, and "Katsu" is from Katsuyama. In short, your favourite part about Katsuyama!\n\nFind out more information about "Oshi-Katsu" `,
      href: `https://www-city-katsuyama-fukui-jp.translate.goog/soshiki/2/28532.html?_x_tr_sl=ja&_x_tr_tl=en&_x_tr_hl=en-US&_x_tr_pto=wapp`,
      linkText: `here`,
      body4: ` (machine translation).`,
      body5: `\n\n\nHow to use the "Oshikatsu" Generator`,
      body6: `\n\nEnter your:\n\n- "Oshi-Katsu"\n- belief for the future\n- nickname\n- age\n- picture\n\nto the generator and you can make your own poster, just like those of the official campaign!`,
      imageNote: `Example poster`,
      body7: `Once your poster has been generated, click on it or the "Download" button below to download your poster.\nIf you upload your poster to social media, remember to use the hashtags "#私の推し勝" and "#勝山しか勝たん山". Or in English "#MyOshiKatsu".\nHave fun with My "Oshi-Katsu" Generator!`,
      note: `\n*The download function is not compatible with iPhone, so if you are using an iPhone please take a screenshot instead.\nYou can take a screenshot by pressing the "volume down" button and the "lock" button at the same time. Sorry for any inconvenience.`,
    },
  };

  let explanation = props.setLanguage ? explanationText.Japanese : explanationText.English;

  return (
    <div className="heading flex-box">
      <h1 className="page-title">{explanation.title}</h1>
      <p className="explanation">
        {explanation.body1}
        <b>{explanation.body2}</b>
        {explanation.body3}<b><a className='explanation-link' target='_blank' rel='noreferrer' href={explanation.href}>{explanation.linkText}</a></b>{explanation.body4}
        <b>{explanation.body5}</b>
        {explanation.body6}
      </p>
      <div className="poster-example-div flex-box">
        <img
          className="poster-example"
          alt="not found"
          src={props.setLanguage ? posterExampleJp : posterExampleEn}
        />
        <p className="poster-example-note"><em>{explanation.imageNote}</em></p>
      </div>
      <p className="explanation">
        {explanation.body7}
      </p>
      <p className="explanation-note">
        {explanation.note}
      </p>
    </div>
  );
}
